.root {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #ececec;
}

.title {
  appearance: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.25rem 0.5rem;
  margin: 0;
  border: none;
  border-radius: 0.25rem;
  background: #ffffff;
  font-family: var(--font-sans-serif);
  font-size: 1rem;
}

.title + .notes {
  margin-top: 0.5rem;
}

.notes {
  appearance: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 4rem;
  padding: 0.25rem 0.5rem;
  margin: 0;
  border: none;
  border-radius: 0.25rem;
  background: #ffffff;
  font-family: var(--font-sans-serif);
  font-size: 0.875rem;
}

.notes + .dateField {
  margin-top: 0.5rem;
}

.dateField {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dateLabel {
  font-family: var(--font-sans-serif);
  font-size: 0.875rem;
}
.dateInput {
  appearance: none;
  display: block;
  box-sizing: border-box;
  width: 70%;
  padding: 0.25rem 0.5rem;
  margin: 0;
  border: none;
  border-radius: 0.25rem;
  background: #ffffff;
  font-family: var(--font-sans-serif);
  font-size: 0.875rem;
}

.dateField + .dateField {
  margin-top: 0.5rem;
}

.dateField + .actions {
  margin-top: 0.5rem;
}

.actions {
  text-align: right;
}

.action + .action {
  margin-left: 0.5rem;
}

.action {
  border: 1px solid #8f8f8f;
  border-radius: 0.25rem;
  background-color: #ececec;
  font-family: var(--font-sans-serif);
  font-size: 0.875rem;
}
.action.primary {
  font-weight: bold;
}
