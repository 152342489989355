.main {
  text-align: center;
}

.authForm {
}

.authForm + .link {
  margin-top: 0.75rem;
}

.link {
  display: inline-block;
  font-size: 0.875rem;
}
