.root {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  border-radius: 0.25rem;
  transition: background-color ease 250ms;
}
.root:hover,
.root:active {
  background-color: #ececec;
}
.root.completed .checkbox {
  color: grey;
  background-color: grey;
  filter: grayscale(1);
}
.root.completed .title {
  color: grey;
}
.root.completed .label {
  text-decoration: line-through;
}

.checkbox {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  background-color: #ffffff;
}

.checkbox + .title {
  margin-left: 0.5rem;
}

.title {
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: unset;
  max-width: calc(100% - 28px);
  padding: 0;
  appearance: none;
  background: none;
  border: none;
  text-align: left;
  font-family: var(--font-sans-serif);
  font-size: 1rem;
}

.date {
  font-size: 0.75em;
  font-weight: bold;
  background-color: #ececec;
  padding: 0.125em 0.25em;
  display: inline-block;
  border-radius: 0.25rem;
}

.label {
  display: flex;
  align-items: center;
  max-width: 100%;
  line-height: 1.2em;
}

.label .date {
  margin-right: 0.5rem;
}

.label + .icons {
  margin-left: 0.5rem;
}

.icons {
  display: flex;
  align-items: center;
  text-align: right;
  white-space: nowrap;
}

.icon {
  line-height: 1em;
}
